.hero {
    display: flex;
    padding: 16px;
    justify-content: space-between;
}

.the-best-ad {
    margin-top: 4rem;
    padding: 16px;
    width: fit-content;
    color: #fff;
    border-radius: 30px;
    position: relative;
    background-color: black;
}

.the-best-ad>div {
    border-radius: 30px;
    position: absolute;
    background-color: chocolate;
    z-index: 1;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.the-best-ad>span {
    z-index: 2;
    position: relative;
}

.hero-heading1 {
    font-size: 20px;
    color: #fff;
    margin: 20px;
}